<template>
  <v-container
    fluid
    tag="section"
  >
    <div class="intro">
      <div class="head-title">
        <div class="icon-box">
          <v-icon>mdi-car-side</v-icon>
        </div>
        <div class="text-h3">
          OTTO - YOUR AI POWERED FLEET ASSISTANT
        </div>
      </div>
      <div class="para">
        <v-avatar
          color="grey lighten-2"
          size="50"
        />
        <div>
          <p class="">
            Hi, I’m Otto—your intelligent fleet assistant! I’m here to help you
            optimize operations, track vehicles, and make data-driven decisions
            for your fleet. Whether it’s telematics insights, route efficiency,
            or cost analysis, just ask, and I’ll handle the rest!
          </p>
          <p>To get started, you can pick from one of these categories.</p>
        </div>
      </div>
    </div>
    <template v-if="!selectedFAQ?.slug">
      <transition
        name="fade-top"
        appear
      >
        <section>
          <div class="faq-section">
            <template v-for="(r, i) in list">
              <FAQBox
                :key="i"
                :class="`box-${i + 1}`"
                :item="r"
                @click="goTo"
              />
            </template>
          </div>
        </section>
      </transition>
      <section>
        <v-container>
          <div id="chat-box">
            <div class="chat-note">
              Or, if you have something specific in mind, just type your
              question, and I’ll take it from there
            </div>
            <v-text-field
              dense
              outlined
              hide-details
              height="80px"
              placeholder="Start Chat"
              @click="goTo('new')"
            />
            <v-btn
              color=""
              @click="goTo('new')"
            >
              <v-icon>mdi-send</v-icon>
              <span>Ask </span>
            </v-btn>
            <!-- <input type="text" /> -->
          </div>
        </v-container>
      </section>
    </template>
    <section v-else>
      <start-chat />
    </section>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ORKOInsights",
  components: {
    FAQBox: () => import("./utils/FAQ-Box.vue"),
    StartChat: () => import("./utils/StartChat.vue"),
  },
  computed: {
    ...mapGetters({
      list: "AIinsights/getList",
      selectedFAQ: "AIinsights/getSelectedFAQ",
    }),
  },
  methods: {
    goTo(v) {
      if (v === "new") {
        // this.$router.push({ name: "chat" });
        this.$store.commit("AIinsights/SET_SELECTED_FAQ", { slug: "new" });
      } else if (v.slug) {
        const r = this.list.find((r) => r.slug == v.slug);
        const qry = {
          slug: v.slug,
          id: r.ask.findIndex((r) => r == v.data) + 1,
        };
        // const routeData = this.$router.resolve({
        //   name: "chat",
        //   query: qry,
        // });
        // window.open(routeData.href, "_blank");
        this.$store.commit("AIinsights/SET_SELECTED_FAQ", qry);
      }
      this.$emit("aaa", v);
    },
  },
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif

.intro
  .head-title
    display: flex
    align-item: center
    gap: 0.5rem
    .icon-box
      background-color: #4f46e5 !important
    .text-h3
      font-weight: 700
      // color: #3C328A !important
      color: #4f46e5 !important
      opacity:1
  .para
    margin-top: 1rem
    display: flex
    gap: 0.5rem
    p
      margin-left: 1rem
      font-size: 17px
      color: #2a3c6f
    div p:first-child
      color: #616161





.faq-section
  display: grid
  grid-template-columns: auto auto auto
  gap:20px
  // column-gap: 1rem
  // row-gap: 1rem


#chat-box
  position: relative
  margin-top: 4rem
  // bottom: 1rem
  // left: 3rem
  // right: 3rem
  .chat-note
    text-align: center
    font-size : 16px
    font-weight: 600
    color: #4f46e5
    padding-bottom: 1rem
    transition : all 0.5s ease
    &:hover
      transform: scale(1.15)
  :deep .v-input
    width: 100%
    .v-input__control
      background: #fff

  input
    width: 100%
    height: 60px
    outline: 1px solid lightgray
    padding: 1rem
    border-radius: 5px
    background:  #fff

  .v-btn
    background: #4f46e5
    position: absolute
    bottom: 1.5rem
    right: 0
    :deep .v-btn__content
      gap: 0.5rem
</style>
